.infoBoxWrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 6rem;
    width: 70rem;
    margin: 0 auto;
    margin-bottom: 6rem;
}

.infoBoxWrapper * {
    background-color: white;
}

.headerBackground {
    background-color: #ddf6f0;
}

.titleWrapper {
    display: flex;
    align-items: baseline;
    align-self: flex-start;
    width: 70rem;
    margin: 0 auto;
    position: relative;
    top: 4rem;
}

.titleWrapper > * {
    margin-right: 1rem;
}

.descriptionWrapper {
    width: 70rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 10rem;
}

.description {
    text-align: left;
}

.responsible {
    display: flex;
    align-items: baseline;
}

.responsible > h3 {
    font-size: 1.8rem;
    font-weight: 100;
}

.responsible > p {
    margin-left: 1rem;
    color: #7c797a;
}
