.groupHeaderWrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 10px;
    font-size: 120%;
    border-bottom: 1px solid black;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.groupHeaderWrapper > span {
    flex: 1;
    font-weight: bold;
}

.groupTitle,
.groupTitleClosed {
    position: relative;
    padding: 1em;
    font-weight: normal;
    cursor: pointer;
    z-index: 1;

    @media screen and (max-width: 900px) {
        text-align: center;
    }
}

.groupTitle::after,
.groupTitleClosed::after {
    content: url(../../../public/img/collapsearrow.svg);
    transform: scale(2);
    position: absolute;
    height: 27px;
    right: 50px;
    transition: 300ms ease-in-out;
    @media screen and (max-width: 900px) {
        display: none;
    }
}

.groupTitle::before,
.groupTitleClosed::before {
    display: block;
    content: '';
    width: 8%;
    left: 46%;
    height: 0.7rem;
    position: absolute;
    bottom: 1.6rem;
    z-index: -1;
    background: #a1f5e3;
    opacity: 0.6;

    @media (max-width: 1300px) {
        width: 16%;
        left: 42%;
    }

    @media (max-width: 1000px) {
        width: 30%;
        left: 35%;
    }

    @media (max-width: 600px) {
        width: 40%;
        left: 30%;
    }
}

.groupTitle:hover,
.groupTitleClosed:hover {
    font-weight: bold;
}

.groupTitle:hover::before,
.groupTitleClosed:hover::before {
    transition: all linear 0.2s;
    background-color: #16dbc4;
    opacity: 0.8;
}

.groupTitleClosed::after {
    transform: scale(2) rotateZ(180deg);
}

.groupsWrapper {
    margin: 0 auto;
    background: white;
    border-radius: 7px;
    box-shadow: 0 6px #e5e5e5;
}

.contentInner {
    padding: 0 2rem;
}

.groupsBox * {
    padding-top: 7px;
    padding-bottom: 7px;

    @media screen and (max-width: 900px) {
        :first-child {
            font-weight: bold;
        }
    }
}
