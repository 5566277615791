@import 'src/assets/styles/base.module.scss';

.invalidTextArea {
    @extend .invalidField;
}

.inputTextArea {
    @extend .applicationFormInputFieldBig;
    padding-top: 15px;
    height: 120px;
    resize: vertical;
}

.inputLabel {
    @extend .formLabel;
}
