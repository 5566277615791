.contentWrapper {
    margin: 0 auto;
    width: 85vw;
    height: 100%;
    padding: 5px;
    overflow: hidden;

    @media screen and (max-width: 900px) {
        width: 95%;
    }
}

.deadLine {
    font-weight: normal;
    margin-left: 5px;
}

.amountCalculation {
    position: fixed;
    padding: 2em;
    right: 10vw;
    top: 40%;
    transform: translateX(-50%);
    width: 10vw;

    @media screen and (max-width: 900px) {
        position: relative;
        width: 100%;
        top: unset;
        transform: unset;
        padding: unset;
        right: unset;
    }
}

.applicationsList {
    float: left;
    width: 80%;
}
