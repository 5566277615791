@import 'src/assets/styles/base.module.scss';

.flex {
    display: flex;
    flex-wrap: wrap;
}

.inputLabel {
    @extend .formLabel;
}

.mutliSelectorInput {
    @extend .applicationFormInputFieldBig;
    height: 40px;
}

.dropdown {
    display: block;
    max-height: 500px;
    width: 400px;
    z-index: 10;
    padding: 0;
    margin: 0;
    align-items: center;
    background-color: #fff;
    font-family: $default-font-family;
    position: absolute;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dropdownListElement {
    display: block;
    padding: 15px;
    &Active {
        @extend .dropdownListElement;
        background-color: #ddd;
    }
    &InActive {
        @extend .dropdownListElement;
    }
}

.dropdownListElement:hover {
    background-color: #ddd;
}
