@import 'src/assets/styles/base.module.scss';

.frontpageWrapper {
    margin: 0 auto;
    text-align: center;
    align-content: center;

    @media screen and (max-width: 800px) {
        margin-top: 0;
    }
}

.feedback {
    width: 60%;
    display: flex;
    margin: 2rem auto;
    justify-content: center;
    align-content: center;
}

.feedbackImage {
    justify-self: center;
    align-self: center;
    width: 15%;
    max-height: 3rem;
    margin: 0 5px;
}

.feedbackText {
    padding-left: 1rem;
    font-size: 130%;
    text-align: left;
    align-self: center;
    width: 70%;
    @media screen and (max-width: 900px) {
        width: 80%;
    }
}

.feedback a {
    color: #000;
}

.titleView {
    background: $primaryLightGreen;
    position: relative;
    padding-bottom: 5rem;
}

.titleText {
    width: 60%;
    margin: 3rem auto;
    font-size: 24px;
    @media screen and (max-width: 900px) {
        width: 80%;
    }
}

.titleText a {
    color: black;
}

.titleText a[href^='slack'] {
    color: #ff8035;
    text-decoration: none;
}

.titleText > * {
    margin-top: 0;
}

.contentWrapper {
    margin: 0 auto;
    width: 70vw;

    @media screen and (max-width: 900px) {
        width: 90vw;
    }
}

.contentView {
    position: relative;
    top: -5rem;
}
