@import 'src/assets/styles/base.module.scss';
@import 'src/assets/styles/_colors.module.scss';
@import 'src/components/ApplicationPage/FormGrid.module.scss';

.guideBoxContainer {
    position: relative;
}

.guideBoxActive {
    @extend .rightColumn;
    padding: $applicationFormInternalPadding;
    box-sizing: border-box;
    width: 400px;
    background-color: $primaryLightGreen;
    position: absolute;
    display: flex;
    @media screen and (max-width: 900px) {
        display: none;
    }
}

.guideBoxInactive {
    display: none;
}

$arrowSide: 15px;

.arrowLeft {
    position: absolute;
    left: -$arrowSide;
    align-self: center;
    width: 0;
    height: 0;
    border-top: $arrowSide solid transparent;
    border-bottom: $arrowSide solid transparent;

    border-right: $arrowSide solid $primaryLightGreen;
}

.title {
    @extend .formLabel;
}

.budgetTableExample {
    width: 100%;
    td:first-child {
        width: 20%;
    }
    td:nth-child(2) {
        width: 20%;
        text-align: center;
    }
    td:nth-child(3) {
        width: 10%;
    }
    td:nth-child(4) {
        width: 20%;
        text-align: center;
    }
    td:nth-child(5) {
        width: 30%;
    }
}
