@import 'src/assets/styles/base.module.scss';

$svgSize: 90px;

.statusBox {
    height: 100%;
    width: fit-content;
    padding: $applicationListItemPadding / 2;
    margin-top: $applicationListItemMargin;
    box-sizing: border-box;
    &:hover {
        cursor: default;
    }
}

.svgContainer {
    display: block;
    height: $svgSize;
    width: $svgSize;
}

.approvedIcon {
    @extend .svgContainer;
    background: url('../../../../../public/img/successFace.svg') no-repeat top left;

    @media screen and (max-width: 900px) {
        width: 40px;
    }
}

.rejectedIcon {
    @extend .svgContainer;
    background: url('../../../../../public/img/sadFace.svg') no-repeat top left;
    @media screen and (max-width: 900px) {
        width: 40px;
    }
}

.unOpenedIcon {
    @extend .svgContainer;
    background: url('../../../../../public/img/unopened.svg') no-repeat center;
    background-size: contain;
    @media screen and (max-width: 900px) {
        width: 40px;
    }
}

.openedIcon {
    @extend .svgContainer;
    background: url('../../../../../public/img/opened.svg') no-repeat center;
    background-size: contain;
    @media screen and (max-width: 900px) {
        width: 40px;
    }
}
