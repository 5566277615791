@import 'src/assets/styles/base.module.scss';

.inputField {
    @extend .applicationFormInputFieldBig;
    height: 40px;
}

.inputLabel {
    @extend .formLabel;
}

.invalidField {
    @extend .invalidField;
}
