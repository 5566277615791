@import 'src/assets/styles/base.module.scss';

.headerWrapper {
    width: 100%;
    /* padding-left: 3rem;
    padding-right: 3rem; */
    padding-right: 1rem;
    padding-top: 0.5rem;

    &Color {
        @extend .headerWrapper;
        background-color: $primaryLightGreen;
    }
    &Gray {
        @extend .headerWrapper;
        background-color: $customGrayLight;
    }
}

.headerLogo {
    display: inline-block;
    height: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 10vw;

    @media screen and (max-width: 1250px) {
        height: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 780px) {
        height: 1.8rem;
        margin-top: 1rem;
    }
}

.button {
    margin: 2.6rem auto;
    margin-right: 2rem;
    padding: 0.6rem;
    width: 10rem;
    height: 2.7rem;
    border-radius: 6px;
    font-weight: bolder;

    @media screen and (max-width: 1250px) {
        padding: 0.2rem;
        width: 8rem;
        height: 2rem;
        margin: 1.4rem 0.5rem;
    }

    @media screen and (max-width: 780px) {
        font-size: 0.8rem;
        padding: 0.1rem;
        height: 1.5rem;
        width: 6rem;
        margin: 0.75rem 0.3rem;
        margin-top: 1rem;
    }
}

.seeApplicationsButton {
    @extend .button;
    float: left;
    border-style: solid;
    background-color: $primaryLightGreen;
    border-color: $primaryDarkGreen;
    color: $primaryDarkGreen;
    box-sizing: border-box;
    &:hover {
        background-color: lighten($primaryLightGreen, 3%);
        cursor: pointer;
    }
}

.applicationButton {
    @extend .button;
    background-color: $primaryDarkGreen;
    color: $customWhite;
    border: none;
    &:hover {
        background-color: lighten($primaryDarkGreen, 2%);
        cursor: pointer;
    }
}

.seeApplicationsButton:hover {
    @extend .seeApplicationsButton;
}

.aux {
    float: right;
}

.piggy {
    float: right;
    height: 4rem;
    margin: 2rem auto;
    align-self: center;
    animation: spin 0.3s linear infinite;
    animation-play-state: paused;

    @media screen and (max-width: 1250px) {
        height: 2.5rem;
        margin: 1rem 0;
    }

    @media screen and (max-width: 780px) {
        height: 1.8rem;
        //  margin: 0.5rem 0;
        margin-top: 0.8rem;
    }

    @keyframes spin {
        0% {
            transform: rotateZ(0deg);
        }
        10% {
            transform: rotateZ(-10deg);
        }
        20% {
            transform: rotateZ(0deg);
        }
        30% {
            transform: rotateZ(10deg);
        }
        40% {
            transform: rotateZ(0deg);
        }
        50% {
            transform: rotateZ(0deg);
        }
        60% {
            transform: rotateZ(-10deg);
        }
        70% {
            transform: rotateZ(0deg);
        }
        80% {
            transform: rotateZ(10deg);
        }
        90% {
            transform: rotateZ(0deg);
        }
    }
}

.piggy:hover {
    animation-play-state: running;
}
