@import 'src/assets/styles/base.module.scss';

.inputField {
    @extend .applicationFormInputFieldSmall;
    height: 40px;
    -webkit-appearance: none;
}

.inputLabel {
    @extend .formLabel;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.invalidField {
    @extend .invalidField;
}
