.infoTitle,
.infoTitleClosed {
    padding: 1em;
    font-weight: normal;
    cursor: pointer;
    z-index: 1;
    position: relative;

    @media screen and (max-width: 900px) {
        text-align: center;
    }
}

.infoTitle::before,
.infoTitleClosed::before {
    display: block;
    content: '';
    width: 8%;
    left: 46%;
    height: 0.7rem;
    position: absolute;
    bottom: 1.6rem;
    z-index: -1;
    background: #a1f5e3;
    opacity: 0.6;

    @media (max-width: 1300px) {
        width: 16%;
        left: 42%;
    }

    @media (max-width: 1000px) {
        width: 30%;
        left: 35%;
    }

    @media (max-width: 600px) {
        width: 40%;
        left: 30%;
    }
}

.infoTitle::after,
.infoTitleClosed::after {
    content: url(../../../../public/img/collapsearrow.svg);
    transform: scale(2);
    position: absolute;
    height: 27px;
    right: 50px;
    transition: 300ms ease-in-out;
    @media screen and (max-width: 900px) {
        display: none;
    }
}

.infoTitleClosed::after {
    transform: scale(2) rotateZ(180deg);
}

.contentInner {
    padding: 0 2rem;
}

.textWrapper {
    border-top: solid lightgrey 1px;
    text-align: left;
    padding: 3rem 5rem;
    @media screen and (max-width: 900px) {
        padding: 2rem;
    }
    @media screen and (max-width: 500px) {
        padding: 2rem 0;
    }
    @media screen and (max-width: 350px) {
        word-break: break-word;
    }
}

.infoText {
    h3 {
        font-weight: normal;
    }
    p {
        color: #6a6869;
        margin-bottom: 2rem;
    }
    h4 {
        font-weight: normal;
    }
}

.infoWrapper {
    margin: 0 auto;
    background: white;
    margin-bottom: 3rem;
    border-radius: 7px;
    box-shadow: 0 6px #e5e5e5;
}
