@import 'src/assets/styles/base.module.scss';
@import 'src/assets/styles/_colors.module.scss';

.assessmentContainer {
    background-color: lightgray;
    display: block;
    padding: 20px 20px 80px 20px;
    margin-right: 70px;

    @media screen and (max-width: 900px) {
        margin: 0px;
    }
}
label {
    @extend .formLabel;
    font-size: 1.5rem;
}
.toggleContainer {
    display: block;
    margin-bottom: 15px;
}

.toggle {
    display: inline-block;
    height: 50px;
    width: 200px;
    padding: 10px;
    text-align: center;
    background-color: gray;
    border: none;
}

.toggleAccept {
    @extend .toggle;
    margin-right: 15px;
    &Active {
        @extend .toggleAccept;
        color: $customWhite;
        font-weight: bolder;
        background-color: $adminGreenDark;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
        &:hover {
            cursor: pointer;
            background-color: lighten($adminGreenDark, 10%);
        }
    }
    &Inactive {
        @extend .toggleAccept;
        background-color: gray;
        opacity: 0.4;
        cursor: pointer;
    }
}

.toggleDecline {
    @extend .toggle;
    &Active {
        @extend .toggleDecline;
        background-color: $customRedDark;
        box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
        &:hover {
            cursor: pointer;
            background-color: lighten($customRedDark, 5%);
        }
    }
    &Inactive {
        @extend .toggleDecline;
        background-color: gray;
        opacity: 0.4;
        cursor: pointer;
    }
}

textarea {
    display: block;
    width: 100%;
    height: 300px;
    resize: vertical;
    border: none;
    font-family: inherit;
    line-height: 1.5rem;
}

button {
    outline: none;
}

.abortAssessment {
    display: inline-block;
    float: right;
    color: inherit;
    border: none;
    background-color: inherit;
    text-decoration: underline;
    font-family: inherit;
    color: black;
    font-size: inherit;
    cursor: pointer;
    &:hover {
        @extend .abortAssessment;
        color: lighten($color: black, $amount: 30%);
    }
}

.sendButton {
    float: right;
    background-color: $adminGreenDark;
    color: white;
    padding: 10px;
    width: 100px;
    border: none;
    margin-top: 10px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    &:hover {
        cursor: pointer;
        background-color: lighten($adminGreenDark, 10%);
    }
    &:active {
        background-color: darken($adminGreenDark, 10%);
    }
}

.inputField {
    @extend .applicationFormInputFieldSmall;
    height: 40px;
}