.infoBox {
    padding: 1rem 2rem;
    width: 13rem;
    border-radius: 10px;
    box-shadow: 0 6px #e5e5e5;
}

.infoType {
    font-size: 1.8rem;
}

.infoContentHeader {
    color: #7c797a;
    margin-bottom: 0;
}

.infoContent {
    margin-top: 0;
    margin-bottom: 2rem;
}
