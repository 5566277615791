@import 'src/assets/styles/base.module.scss';

.collapseBudgetArrow {
    &:hover {
        cursor: pointer;
    }
}

.collapseIcon {
    vertical-align: middle;
    &:hover {
        cursor: pointer;
    }
}

.descriptionList {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90%, 0.6fr));
    row-gap: 5px;

    dt {
        font-weight: bold;
        grid-column-start: 1;
    }

    dd {
        grid-column-start: span 2;
    }

    // For alternating color in the descriptionList
    // dt,
    dd {
        background-color: #ccc;
        margin-inline-start: 0px;
    }

    dd:nth-child(2) {
        background-color: #fff;
    }

    /*dt:nth-child(4n + 1),
    dt:nth-child(4n + 1) + dd {
        background-color: #ffff;
    }*/
}

.contactPersonsList {
    list-style-type: none;
    padding: 0;
    li {
        list-style-type: none;
    }
}

.inputField {
    @extend .applicationFormInputFieldSmall;
    height: 40px;
}

.button {
    padding: 10px;
    border: none;
    width: 100px;
    color: $customWhite;
    font-weight: bolder;
    &:hover {
        cursor: pointer;
    }
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
}

.submitButton {
    @extend .button;
    margin-right: 10px;
    background-color: $adminGreenDark;
    &:hover {
        background-color: lighten($adminGreenDark, 10%);
    }
}

.cancelButton {
    @extend .button;
    background-color: $customRedDark;
    &:hover {
        background-color: lighten($customRedDark, 5%);
    }
}

.headerInputContainer {
    display: flex;

    & > *:first-child {
        flex: 3;
    }

    & > *:last-child {
        flex: 1;
    }
}

.budgetEditContainer {
    padding: 5px;
}
