.grantedAmountBox {
    background-color: #ffffff;
    width: 25vw;
    height: fit-content;
    border: none;
    padding: 15px;
    overflow: hidden;

    @media screen and (max-width: 900px) {
        width: 95%;
        padding: 10px;
    }
}
