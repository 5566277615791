.removeIconContainer {
    width: 100%;
    display: inline-block;
    height: auto;
    position: relative;
    padding-top: 100%;
    margin-left: 8px;
}

.removeIconContainer svg {
    overflow: visible;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.removeIcon svg {
    fill: #3b3b3b;
}

.removeIcon:hover svg {
    fill: #000;
}

.removeIcon {
    width: 10px;
    cursor: pointer;
    fill: #3b3b3b;
    display: inline-block;
}
