.groupWrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-top: solid lightgrey 1px;
    min-height: 80px;
    align-items: center;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
}

.groupWrapper > span {
    flex: 1;
}

.groupName {
    font-weight: normal;
}

.groupWrapper a {
    text-decoration: none;
    color: #ff8035;
}

.twoLineInfo > * {
    margin: 0;
}

.twoLineInfo {
    :first-child {
        color: #7c797a;
        text-transform: uppercase;
        padding: 0;
    }
    :last-child {
        padding: 0;
    }
}
