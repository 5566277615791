$primaryLightGreen: #d2faee;
$primaryDarkGreen: #16dbc4;
$adminGreenLight: #a3efe1;
$adminGreenDark: #4be1c6;
$customBlack: #0e0e0e;
$customGrayDark: #b6b6b6;
$customGrayLight: #f6f6f6;
$customWhite: #ffffff;
$customRedLight: #ffd2c9;
$customRedDark: #ffaaa5;
$customYellow: #f2d541;
$customOrange: #ff8034;
