.formGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.leftColumn {
    grid-column: 1 / 4;
    width: 100%;
}

.rightColumn {
    grid-column: 4 / 6;
}
