@import './colors.module';

$default-font-family: 'DIN OT';
$applicationListItemMargin: 10px;
$applicationListItemPadding: 20px;
$applicationFormInternalPadding: 30px;

*,
*::after,
*::before {
    box-sizing: inherit;
    font-family: inherit;
}

html {
    min-height: 100%;
    background-color: $customGrayLight;
}

body {
    box-sizing: border-box;
}

.formLabel {
    font-family: $default-font-family;
    font-size: 1.2rem;
    display: inline-block;
    margin-bottom: 15px;
}

.applicationFormInputField {
    border: none;
    padding-left: 15px;
    padding-top: 1.4rem;
    padding-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: lighter;
    display: flex;
    font-family: $default-font-family;
    outline-color: $primaryLightGreen;
    &:focus {
        background-color: $primaryLightGreen;
    }

    &Small {
        @extend .applicationFormInputField;
        width: 100px;
        background-color: $customGrayLight;
    }
    &Big {
        @extend .applicationFormInputField;
        max-width: 400px;
        background-color: $customGrayLight;
    }
}

.invalidField {
    border: solid 1px $customRedLight;
}

.validField {
    border: solid 1px white;
}

.actionButton {
    display: inline-block;
    color: black;
    background-color: rgb(210, 210, 210);
    padding: 11px 13px;
    margin: 3px;
    border: none;

    &:disabled {
        background-color: #cccccc;
        color: #666666;
        opacity: 0.3;
    }

    &:enabled:hover {
        cursor: pointer;
    }

    &Accept {
        @extend .actionButton;
        background-color: $primaryDarkGreen;
        color: white;
        &:enabled:hover {
            background-color: lighten($primaryDarkGreen, 10%);
        }
    }

    &Reject {
        @extend .actionButton;

        background-color: lightcoral;
        color: white;
        &:enabled:hover {
            background-color: red;
        }
    }
}
