@import 'src/assets/styles/base.module.scss';

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-self: center;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    &:last-child {
        margin-bottom: 10px;
    }
}

.inputField {
    @extend .applicationFormInputFieldSmall;
    height: 40px;

    @media screen and (max-width: 900px) {
        width: 75px;
    }
}
