@import 'src/assets/styles/base.module.scss';

.listItem {
    display: flex;
    flex-direction: column;
    padding: $applicationListItemPadding;
    margin-top: $applicationListItemMargin;
    flex: 1;
    background-color: white;
    min-width: 50%;
    overflow: hidden;

    @media screen and (max-width: 900px) {
        // height: 70px;
        width: 100%;
        min-width: 100%;
        padding: 10px;
    }
}

.header {
    display: flex;
    min-width: 400px;
    @media screen and (max-width: 900px) {
        min-width: 50px;
        height: 50px;
    }
    h4 {
        flex: 1;
        text-align: right;
    }

    h3 {
        flex: 2;
        float: left;
        @media screen and (max-width: 900px) {
            margin: 0;
        }
    }

    p {
        flex: 1;
    }

    @media only screen and (max-width: 600px) {
        h4 {
            display: none;
        }

        p {
            display: none;
        }
    }
}

.collapseIcon {
    vertical-align: middle;
    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: 900px) {
        margin: 0;
        padding: 0;
        vertical-align: top;
    }
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.makeChangesButton {
    @extend .actionButtonAccept;
    width: 12vw;

    @media screen and (max-width: 900px) {
        min-width: 85px;
        padding: 7px;
        font-size: 14px;
    }
}

.applicationPreviewContainer {
    display: flex;
    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

.applicationContainer {
    display: block;
    margin-bottom: $applicationListItemMargin;
}

.statusBoxContainer {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 900px) {
        min-width: 80px;
    }
}
