@import 'src/assets/styles/base.module.scss';

$defaultButtonHeight: 48px;

.invalidField {
    @extend .invalidField;
}

.validField {
    @extend .validField;
}

.budgetInputField {
    height: $defaultButtonHeight;
    display: flex;
    font-size: 1rem;
    font-family: $default-font-family;
    font-weight: lighter;
    flex: 1;
    padding-left: 15px;
    background-color: $customGrayLight;
    outline: none;
    &:focus {
        background-color: $primaryLightGreen;
        border: none;
    }
}

.budgetEntry {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    @media screen and (max-width: 900px) {
        flex-wrap: wrap;
        border-bottom-style: solid;
        border-width: 1px;
        padding-bottom: 10px;
        border-color: lightgrey;
    }
}

.budgetInputText {
    @extend .budgetInputField;
    width: 12vw;
    max-width: 12rem;
}

.budgetInputValue {
    @extend .budgetInputField;
    width: 6vw;
    max-width: 6rem;
    margin-left: 5px;
}
.deleteButton {
    width: 4vw;
    max-width: 60px;
    min-width: 30px;
    height: $defaultButtonHeight;
    margin-left: 2.5rem;
    background-color: $customGrayLight;
    border: none;
    &:hover {
        cursor: pointer;
        background-color: darken($customGrayLight, 2%);
    }

    @media screen and (max-width: 900px) {
        width: 50px;
        height: 40px;
        margin-top: 10px;
        margin-left: 10px;
    }
}

.basisButtonGroup {
    margin-left: 1rem;
    display: flex;
    float: left;

    @media screen and (max-width: 900px) {
        margin-left: 1px;
    }
}

.basisButton {
    height: $defaultButtonHeight;
    width: 6vw;
    min-width: 60px;
    max-width: 120px;
    margin-left: 5px;
    font-size: 1rem;
    border: none;

    &Active {
        @extend .basisButton;
        background-color: $primaryDarkGreen;
        color: rgba($color: white, $alpha: 0.9);
    }
    &Inactive {
        @extend .basisButton;
        background-color: white;
        border: solid 1px $primaryDarkGreen;
        color: rgba($color: $primaryDarkGreen, $alpha: 0.8);
        &:hover {
            border-color: lighten($primaryDarkGreen, 10%);
            color: lighten($primaryDarkGreen, 10%);
            cursor: pointer;
        }
    }

    @media screen and (max-width: 900px) {
        width: 100px;
        margin-top: 10px;
        margin-left: 0;
        padding: 0;
        height: 40px;
    }
}

.horizontalContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}
