@import 'src/assets/styles/base.module.scss';

.multiSelectSelectedValue {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: $default-font-family;
    font-weight: lighter;
    background-color: $customGrayLight;
}

.multiSelectSelectedValueRemovable {
    @extend .multiSelectSelectedValue;
    &:hover {
        background: darken($customGrayLight, 5%);
        overflow: hidden;
    }
}
