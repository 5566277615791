@import 'src/assets/styles/base.module.scss';

.budgetInputContainer {
    flex-direction: column;
}

.addBudgetEntryButton {
    width: 12vw;
    max-width: 12rem;
    height: 40px;
    border: none;
    &:hover {
        background-color: darken($customGrayLight, 5%);
        cursor: pointer;
    }

    @media screen and (max-width: 900px) {
        width: 110px;
    }
}

.inputLabel {
    @extend .formLabel;
}
