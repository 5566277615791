@import 'src/assets/styles/base.module.scss';

.pageWrapper {
    width: 100%;
    @media screen and (max-width: 800px) {
        margin-top: 0;
    }
}

.titleView {
    background: $primaryLightGreen;
    position: relative;
    padding-bottom: 5rem;
}

.titleText {
    width: 60%;
    margin: 3rem auto;
    font-size: 24px;
    a {
        color: $customBlack;
    }
    @media screen and (max-width: 900px) {
        width: 80%;
    }
}

.contentView {
    position: relative;
    top: -5rem;
}

.contentWrapper {
    width: 70vw;
    position: relative;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 400px) {
        width: 95vw;
    }
}

.applicationButton {
    border: none;
    padding: 1rem;
    font-size: 1rem;
    width: 20rem;
    color: $customWhite;
    background-color: $primaryDarkGreen;
    border-radius: 7px;
    font-weight: bolder;
    @media screen and (max-width: 800px) {
        width: 20rem;
    }
    @media screen and (max-width: 500px) {
        width: 15rem;
    }
}

.applicationButton:hover {
    @extend .applicationButton;
    background-color: lighten($primaryDarkGreen, 2%);
    cursor: pointer;
}
