@import 'src/assets/styles/base.module.scss';
@import './FormGrid.module.scss';

.pageContainer {
    margin: 0 auto;
    padding-right: 10rem;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: $customGrayLight;
    background-image: url('../../../public/spirit_illustration.svg');
    background-repeat: no-repeat;
    background-position: top 40rem left 7rem;
    background-size: 80%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 900px) {
        padding: 0;
    }
}

/* .contentWrapper {
    margin: 0 0 3rem 0;
    width: 100%;
    min-width: 37.5rem;
    max-width: 82rem;
    float: left;
    margin-left: 10vw;
    padding: 3rem;

    @media screen and (max-width: 900px) {
        margin: 0;
        width: 100%;
        max-width: 100%;
        min-width: 375px;
        padding: 0;
    }
} */

.title {
    font-family: $default-font-family;
    font-weight: bolder;
    font-size: 2rem;
}

.formGuidanceGrid {
    @extend .formGrid;
    @media screen and (max-width: 900px) {
        display: block;
    }
}

.submitButton {
    @extend .actionButtonAccept;
    margin-top: 15px;
    width: 10vw;
    min-width: 5rem;
    height: 3rem;
    font-size: 1rem;

    @media screen and (max-width: 900px) {
        min-width: 120px;
    }
}

.componentContainer {
    @extend .leftColumn;
    background-color: $customWhite;
    padding: $applicationFormInternalPadding;
    padding-left: 3rem;
    width: 100%;
    @media screen and (max-width: 900px) {
        background-color: $customWhite;
    }
}

.formOutsideGuidanceGrid {
    @extend .formGrid;

    @media screen and (max-width: 900px) {
        background-color: $customWhite;
    }
}

.componentOutsideGuidanceGrid {
    @extend .componentContainer;
}

.header {
    @extend .componentContainer;
    padding-top: 3rem;
    margin: 0;
}
