@import 'src/assets/styles/base.module.scss';

.errorContainer {
    position: relative;
}

.error {
    position: absolute;
    color: $customRedDark;
    font-weight: bolder;
}
